import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import Menu from './Menu';
import Logo from './Logo';
import PhoneWithEmail from './address/PhoneWithEmail';

class LogoWithNav extends Component {
    render() {
        return (
            <Fragment>

                <Container>
                    <Row>
                        <Col lg={2} md={4} sm={6} xs={6}>
                            <Logo />
                        </Col>
                        <Col lg={7} md={4} sm={6} xs={6}>
                            <Menu />
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={6}>
                            <div className='Desktop'> <a class="btn-one" href={process.env.PUBLIC_URL + "/apply/" + "apply-now"} >Apply Now</a></div>

                        </Col>
                    </Row>
                </Container>

            </Fragment>
        )
    }
}

export default LogoWithNav
