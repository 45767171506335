import React, { Component, Fragment, Suspense, lazy } from 'react';

const DesktopMenu = React.lazy(() => import("./DesktopMenu"))

class DesktopNavbarMenu extends Component {

    render() {
        return (
            <Fragment>

                <Suspense>   <DesktopMenu /></Suspense>

            </Fragment>
        )
    }
}

export default DesktopNavbarMenu
