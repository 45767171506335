import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Favicon from './Favicon';
import JavaScriptTop from './JavaScriptTop';
import Logo from './Logo';
import LogoWithAddress from './LogoWithAddress';
import LogoWithNav from './LogoWithNav';
import Menu from './Menu';
import News from './News';
import TopHeader from './TopHeader';


class Header extends Component {
  componentDidMount() {
    window.scroll(0, 0)
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <JavaScriptTop />
        </Helmet>
        <Favicon />
        <News />
        <TopHeader />
        <LogoWithNav />
      </Fragment>

    )
  }
}

export default Header;